// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import EntryForm from './components/EntryForm';
import EditEntryForm from './components/EditEntryForm';
import EntryManagementPage from './components/EntryManagementPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons'; // Icons importieren

function App() {
    return (
        <Router>
            <div className="container">
                <nav>
                    <ul>
                        <li>
                            <Link to="/">
                                <FontAwesomeIcon icon={faPlus} /> Eintragsformular
                            </Link>
                        </li>
                        <li>
                            <Link to="/entries">
                                <FontAwesomeIcon icon={faList} /> Einträge verwalten
                            </Link>
                        </li>
                    </ul>
                </nav>
                <Routes>
                    <Route exact path="/" element={<EntryForm />} />
                    <Route exact path="/entries" element={<EntryManagementPage />} />
                    <Route path="/edit/:id" element={<EditEntryForm />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;