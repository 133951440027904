// src/components/EntryManagementPage.js
import React, { useState, useEffect } from 'react'; // useState und useEffect importiert
import axios from 'axios';
import qs from 'qs'; // Neu hinzugefügt
import DynamicCheckboxes from './DynamicCheckboxes';
import EditEntryForm from './EditEntryForm'; // Import des EditEntryForm-Komponents
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTrash, faEdit, faDownload, faExpand, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Icons importieren
import './EntryManagementPage.css';
import './DynamicCheckboxes.css'; // Importiere die CSS-Datei von DynamicCheckboxes

function EntryManagementPage() {
    const [entries, setEntries] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [kategorien, setKategorien] = useState([]);
    const [filterKategorien, setFilterKategorien] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterModalKategorien, setFilterModalKategorien] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState(null);

    useEffect(() => {
        fetchKategorien();
    }, []);

    useEffect(() => {
        fetchEntries();
    }, [page, limit, filterKategorien, nameFilter]);

    const fetchKategorien = () => {
        axios.get('https://api.divingmaster.net/api/categories')
            .then(response => {
                setKategorien(response.data);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Kategorien:', error);
            });
    };

    const fetchEntries = () => {
        const filters = {
            page,
            limit,
        };

        if (nameFilter) {
            filters.name = nameFilter;
        }

        if (filterKategorien.length > 0) {
            // Serialisiere filterKategorien zu einem JSON-String
            filters.kategorien = JSON.stringify(filterKategorien);
        }

        axios.get('https://api.divingmaster.net/api/entries', {
            params: filters,
            paramsSerializer: params => {
                return qs.stringify(params); // Keine speziellen Optionen nötig
            }
        })
            .then(response => {
                const entriesWithExpanded = response.data.entries.map(entry => ({
                    ...entry,
                    isExpanded: false,
                }));
                setEntries(entriesWithExpanded);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Einträge:', error);
            });
    };

    const handleOpenFilterModal = () => {
        const kategorienCopy = JSON.parse(JSON.stringify(kategorien));
        setFilterModalKategorien(kategorienCopy);
        setShowFilterModal(true);
    };

    const handleCloseFilterModal = () => {
        setShowFilterModal(false);
    };

    const handleApplyFilters = () => {
        const selectedOptions = [];

        filterModalKategorien.forEach(category => {
            category.unterkategorien.forEach(sub => {
                sub.optionen.forEach(option => {
                    if (option.isChecked) {
                        selectedOptions.push({
                            category: category.name,
                            subcategory: sub.name,
                            option: option.name
                        });
                    }
                });
            });
        });

        setFilterKategorien(selectedOptions);
        setShowFilterModal(false);
    };

    const handleFilterKategorienChange = (updatedKategorien) => {
        setFilterModalKategorien(updatedKategorien);
    };

    const handleDeleteEntry = (id) => {
        if (window.confirm('Möchten Sie diesen Eintrag wirklich löschen?')) {
            axios.delete(`https://api.divingmaster.net/api/entries/${id}`)
                .then(response => {
                    alert('Eintrag erfolgreich gelöscht');
                    fetchEntries();
                })
                .catch(error => {
                    console.error('Fehler beim Löschen des Eintrags:', error);
                });
        }
    };

    const handleEditEntry = (entry) => {
        setEntryToEdit(entry);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEntryToEdit(null);
        // Nach dem Schließen des Modals die Einträge neu laden
        fetchEntries();
    };

    const downloadImage = (entryId, imageId) => {
        window.open(`https://api.divingmaster.net/api/entries/${entryId}/images/${imageId}`);
    };

    const downloadAllImages = (entryId) => {
        window.open(`https://api.divingmaster.net/api/entries/${entryId}/images/download`);
    };

    const handleEntryClick = (index) => {
        setEntries(prevEntries => prevEntries.map((entry, idx) => {
            if (idx !== index) return entry;
            return { ...entry, isExpanded: !entry.isExpanded };
        }));
    };

    return (
        <div className="entry-management">
            <h1>Einträge verwalten</h1>
            <button onClick={handleOpenFilterModal}>
                <FontAwesomeIcon icon={faFilter} /> Filter
            </button>
            {/* Filter Modal */}
            {showFilterModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Filter anwenden</h2>
                        
                        {/* Eingabefeld zum Suchen nach Namen */}
                        <div className="section">
                            <label htmlFor="nameFilter">Name:</label>
                            <input
                                type="text"
                                id="nameFilter"
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                            />
                        </div>
                        
                        {/* DynamicCheckboxes für Filter */}
                        <DynamicCheckboxes
                            kategorien={filterModalKategorien}
                            setKategorien={handleFilterKategorienChange}
                            isFilter={true}
                        />
                        
                        {/* Button-Gruppe */}
                        <div className="button-group">
                            <button onClick={handleApplyFilters}>Anwenden</button>
                            <button onClick={handleCloseFilterModal}>Schließen</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Einträge Liste */}
            <div className="entries-list">
                {entries.map((entry, index) => {
                    return (
                        <div key={entry._id} className="entry-item">
                            <h2 onClick={() => handleEntryClick(index)}>
                                {entry.isExpanded ? '[-]' : '[+]'} {entry.name}
                            </h2>
                            {entry.isExpanded && (
                                <div className="entry-details">
                                    <div className="entry-actions">
                                        <button onClick={() => handleDeleteEntry(entry._id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Löschen
                                        </button>
                                        <button onClick={() => handleEditEntry(entry)}>
                                            <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                                        </button>
                                        <button onClick={() => downloadAllImages(entry._id)}>
                                            <FontAwesomeIcon icon={faDownload} /> Alle Bilder herunterladen
                                        </button>
                                    </div>

                                    {/* Notizen des Eintrags anzeigen */}
                                    <div className="entry-notes-box">
                                        <h3>Notizen:</h3>
                                        {entry.notes && entry.notes.trim() !== '' ? (
                                            <p>{entry.notes}</p>
                                        ) : (
                                            <p>Keine Notizen vorhanden.</p>
                                        )}
                                    </div>

                                    {/* Bilder des Eintrags anzeigen */}
                                    <div className="entry-images">
                                        {entry.images.map(image => (
                                            <div key={image._id} className="image-item">
                                                <img
                                                    src={`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`}
                                                    alt={image.name}
                                                    onClick={() => window.open(`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`)}
                                                />
                                                {/* Button durch Icon ersetzen */}
                                                <button onClick={() => window.open(`https://api.divingmaster.net/api/entries/${entry._id}/images/${image._id}`)}>
                                                    <FontAwesomeIcon icon={faExpand} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {/* Edit Modal */}
            {isEditModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={handleCloseEditModal}>X</button>
                        <EditEntryForm entry={entryToEdit} onClose={handleCloseEditModal} />
                    </div>
                </div>
            )}

            {/* Paginierung */}
            <div className="pagination">
                <button onClick={() => setPage(prev => Math.max(prev - 1, 1))}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Vorherige
                </button>
                <span>Seite {page} von {totalPages}</span>
                <button onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}>
                    Nächste <FontAwesomeIcon icon={faArrowRight} />
                </button>
                <label>
                    Einträge pro Seite:
                    <input
                        type="number"
                        value={limit}
                        onChange={(e) => setLimit(parseInt(e.target.value))}
                    />
                </label>
            </div>
        </div>
    );
}

export default EntryManagementPage;