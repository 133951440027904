// src/components/SubmitButton.js
import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons'; // Icon importieren

function SubmitButton({ data }) {
    const handleSubmit = () => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('notes', data.notes);

        data.images.forEach((image) => {
            formData.append('images', image.file);
        });

        // Ermitteln der ausgewählten Optionen mit Kategorien und Unterkategorien
        const selectedOptions = [];
        data.kategorien.forEach(category => {
            category.unterkategorien.forEach(sub => {
                sub.optionen.forEach(option => {
                    if (option.isChecked) {
                        selectedOptions.push({
                            category: category.name,
                            subcategory: sub.name,
                            option: option.name
                        });
                    }
                });
            });
        });

        formData.append('kategorien', JSON.stringify(selectedOptions));

        axios.post('https://api.divingmaster.net/api/entries', formData)
            .then((response) => {
                alert('Eintrag erfolgreich erstellt');
                // Weiterleitung oder weitere Aktionen
            })
            .catch((error) => {
                console.error('Fehler beim Erstellen des Eintrags:', error);
            });
    };

    return (
        <button onClick={handleSubmit}>
            <FontAwesomeIcon icon={faSave} /> Speichern
        </button>
    );
}

export default SubmitButton;